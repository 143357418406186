import React, { useState, useEffect } from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Article from "../../components/article"
import useWindowSize from "../../utilt/useWindowSize"

//CSS
import { eachTitle } from "./otherDrawings.module.css"
import { desc } from "./otherDrawings.module.css"
import { thumNails } from "./otherDrawings.module.css"
import { thumNail } from "./otherDrawings.module.css"
import { curThumNail } from "./otherDrawings.module.css"
import { thumbImg } from "./otherDrawings.module.css"
import { modalArea } from "./otherDrawings.module.css"
import { isShowCSS } from "./otherDrawings.module.css"
import { modalBg } from "./otherDrawings.module.css"
import { modalWrapperWrapper } from "./otherDrawings.module.css"
import { modalWrapperScale } from "./otherDrawings.module.css"
import { modalWrapper } from "./otherDrawings.module.css"
import { decription } from "./otherDrawings.module.css"
import { close } from "./otherDrawings.module.css"


import getLanguage from "../../utilt/getLangueage"
import calcModalSize from "../../utilt/calcModalSize"
import getIsTouchscreen from "../../utilt/getIsTouchscreen"

//import seoImg from "../../images/seo/seo_others.jpg"

/**
 * ページ
 */
const Others = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }


  //画像データ取得
  const data = useStaticQuery(graphql`query MyQueryOthers {
  allFile(
    filter: {extension: {regex: "/(jpg)/"}, relativeDirectory: {eq: "others"}}
  ) {
    edges {
      node {
        base
        childImageSharp {
          gatsbyImageData(quality: 50, placeholder: DOMINANT_COLOR, layout: CONSTRAINED)
        }
      }
    }
  }
  allOthersJson {
    edges {
      node {
        title
        series
        seriesJP
        descriptionJp
        description
      }
    }
  }
}
`)
  //1 取得した画像データを配列化
  const imagesOld = data.allFile.edges.map(n => n.node);
  const images = imagesOld.slice();
  //2 ファイル名でソート
  function compare(a, b) {
    const baseA = a.base;
    const baseB = b.base;

    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  images.sort(compare);
  for (let i = 0; i < images.length; i++) {
    images[i].index = i;
  }
  //3 配列を二分してサムネイルとモーダル表示用画像を分ける
  const ImagesLength = Math.floor(images.length / 2);
  const modalImages = images.slice(0, ImagesLength);
  const thumbsImages = images.slice(ImagesLength);
  //4 サムネイルにindexを付ける
  for (let i = 0; i < ImagesLength; i++) {
    modalImages[i].index = i;
    thumbsImages[i].index = i;
  }

  //5 JSONデータを配列化
  const descJsons = data.allOthersJson.edges.map(n => n.node);
  //6 JSONもファイル名でソート
  function compareJason(a, b) {
    const baseA = a.title;
    const baseB = b.title;

    let comparison = 0;
    if (baseA > baseB) {
      comparison = 1;
    } else if (baseA < baseB) {
      comparison = -1;
    }
    return comparison;
  }
  descJsons.sort(compareJason);
  for (let i = 0; i < descJsons.length; i++) {
    descJsons[i].index = i;
  }

  //console.log(ImagesLength, modalImages[ImagesLength - 1]);


  /**
   * モダール用
   */
  const [isShow, setIsShow] = useState(false);
  const [picNum, setPicNum] = useState(0);

  function handleModal(event, index) {
    event.stopPropagation();
    if (typeof index == "number") {
      setPicNum(index);
    }
    setIsShow(!isShow);
  }

  function handleModalKey(event, index) {
    if (event.keyCode === 13) { //Enter key
      if (typeof index == "number") {
        setPicNum(index);
      }
      setIsShow(true);
    }
  }

  function clickModal(event) {
    event.stopPropagation();
    const modal = document.getElementById("modalContent");
    const clientRect = modal.getBoundingClientRect();

    if (event.clientX > clientRect.left + clientRect.width * 0.5) {
      setPicNum(picNum => (picNum + 1) % ImagesLength);
    } else {
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
    }
  }

  //for touchscreen
  useEffect(() => {
    function plusPicNum() {
      setPicNum(picNum => (picNum + 1) % ImagesLength);
    }

    function minusPicNum() {
      setPicNum(picNum => picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1);
    }

    let handleTouchStart, handleTouchMove, handleTouchEnd;
    if (getIsTouchscreen()) {
      let startX;
      let moveX;
      let dist = 150;
      let isStart = false;

      handleTouchStart = e => {
        //e.preventDefault();
        if (!isStart) {
          isStart = true;
          startX = e.touches[0].pageX;
        }
      }
      handleTouchMove = e => {
        //e.preventDefault();
        moveX = e.changedTouches[0].pageX;
      }
      handleTouchEnd = e => {
        if (isStart) {
          isStart = false;
          if (startX && moveX) {
            if (startX > moveX && startX > moveX + dist) { // 右から左にスワイプ
              //e.preventDefault()
              plusPicNum();
            } else if (startX < moveX && startX + dist < moveX) { // 左から右にスワイプ
              //e.preventDefault()
              minusPicNum();
            }
            startX = null;
            moveX = null;
          }
        }
      }

      document.addEventListener('touchstart', handleTouchStart);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd);
    }
    return () => {
      if (getIsTouchscreen()) {
        document.removeEventListener('touchstart', handleTouchStart);
        document.removeEventListener('touchmove', handleTouchMove);
        document.removeEventListener('touchend', handleTouchEnd);
      }
    }
  }, [picNum, ImagesLength])


  //キーボード操作でモダール画像チェンジ
  useEffect(() => {
    function handlePicNumKey(event) {
      if (event.key === "ArrowRight" || event.key === "Tab") {
        let result = (picNum + 1) % ImagesLength;
        setPicNum(result);
      } else if (event.key === "ArrowLeft") {
        let result = picNum - 1 < 0 ? ImagesLength - 1 : picNum - 1;
        setPicNum(result);
      } else if (event.keyCode === 27) {
        setIsShow(false);
      }
    }

    //画像選択にfocusをシンクロさせる
    if (isShow) {
      document.getElementById(`pic${String(picNum)}`).focus();
      //console.log(document.getElementById(`pic${String(picNum)}`));
    }

    window.addEventListener('keydown', handlePicNumKey);
    return () => {
      window.removeEventListener('keydown', handlePicNumKey);
    }
  }, [picNum, isShow, ImagesLength])

  const modaleScale = 2.25;

  return <>
    <Seo
      title="Other Drawings in the 2000s"
      description={lang === "en" ?
        "Other drawings(2002 - 2007)" :
        "2002年から2007年の間に描いたその他のドローイング作品"
      }
      lang={lang}
      image="https://www.drawinghell.com/ogp_images/seo_others.jpg"
      pathname="/work/otherDrawings"
    />


    {/**
       * ページ＆サムネイル
       */}
    <Layout
      checked="worksParts"
      winSize={winSize}
    >
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <h1
          className={eachTitle}
        >
          {lang === "en" ? "Other Drawings in the 2000s" : "その他のドローイング"}
        </h1>
        <p className={desc}>
          {lang === "en" ?
            `2002 - 2007` :
            `2002 - 2007年`}
        </p>

        {/**
         * サムネイル
        */}
        <div
          className={thumNails}
        >
          {thumbsImages.map((node) => (
            <div
              className={isShow && picNum === node.index ? `${thumNail} ${curThumNail}` : thumNail}
              style={{
                width: `${modalImages[node.index].childImageSharp.gatsbyImageData.width * 0.165}px`
              }}
              onClick={event => handleModal(event, node.index)}
              onKeyDown={event => handleModalKey(event, node.index)}
              role="button"
              tabIndex={0}
              key={node.base.split(".")[0]}
              id={"pic" + String(node.index)}
              title={lang === "en" ? descJsons[node.index].series : descJsons[node.index].seriesJP}
            >
              <GatsbyImage
                image={node.childImageSharp.gatsbyImageData}
                alt={node.base.split(".")[0]}
                className={thumbImg} />
            </div>
          ))}
          <div
            className={thumNail}
            style={{
              width: `227px`,
              marginBottom: "0"
            }}
          > </div>
          <div
            className={thumNail}
            style={{
              width: `227px`,
              marginBottom: "0"
            }}
          > </div>
          <div
            className={thumNail}
            style={{
              width: `227px`,
              marginBottom: "0"
            }}
          > </div>
          <div
            className={thumNail}
            style={{
              width: `227px`,
              marginBottom: "0"
            }}
          > </div>
          <div
            className={thumNail}
            style={{
              width: `227px`,
              marginBottom: "0"
            }}
          > </div>

        </div>
      </Article>
    </Layout>


    {/**
      * モーダル      
    */}
    <div
      className={
        isShow ? `${modalArea} ${isShowCSS}` : modalArea
      }
    >
      <div
        className={modalBg}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >

        <div
          className={
            isShow ? `${modalWrapperWrapper} ${modalWrapperScale}` : modalWrapperWrapper
          }
          style={{
            width:
              calcModalSize(winSize,
                modalImages[picNum].childImageSharp.gatsbyImageData.width * 0.25,
                modalImages[picNum].childImageSharp.gatsbyImageData.width / modalImages[picNum].childImageSharp.gatsbyImageData.height, modaleScale, 0.9) + "px"
          }}
        >

          <div
            className={modalWrapper}
            onClick={event => clickModal(event)}
            onKeyDown={() => { }}
            role="button"
            tabIndex={0}
            id="modalContent"
          >
            <div
            >
              <GatsbyImage
                image={modalImages[picNum].childImageSharp.gatsbyImageData}
                alt={modalImages[picNum].base.split(".")[0]} />
              <div
                className={decription}
              >
                {lang === "en" ? descJsons[picNum].description : descJsons[picNum].descriptionJp}
              </div>

            </div>
          </div>
        </div>
      </div>
      <span
        className={close}
        onClick={event => handleModal(event)}
        onKeyDown={() => { }}
        role="button"
        tabIndex={0}
      >
        ×
      </span>
    </div>

  </>;
}

export default Others
