// extracted by mini-css-extract-plugin
export var close = "otherDrawings-module--close--8f+DR";
export var curThumNail = "otherDrawings-module--curThumNail--R5GjW";
export var decription = "otherDrawings-module--decription--TSwnJ";
export var desc = "otherDrawings-module--desc--wngwZ";
export var eachTitle = "otherDrawings-module--eachTitle--wJVmO";
export var imgAppear = "otherDrawings-module--imgAppear--wzzw9";
export var imgHide = "otherDrawings-module--imgHide--Fj+Tm";
export var isShowCSS = "otherDrawings-module--isShowCSS--bYu+-";
export var modalArea = "otherDrawings-module--modalArea--E+bHC";
export var modalBg = "otherDrawings-module--modalBg--wMp6H";
export var modalWrapper = "otherDrawings-module--modalWrapper--DXQ1x";
export var modalWrapperScale = "otherDrawings-module--modalWrapperScale--bE5Lo";
export var modalWrapperWrapper = "otherDrawings-module--modalWrapperWrapper--BeVKu";
export var thumNail = "otherDrawings-module--thumNail--xFyHG";
export var thumNailUme = "otherDrawings-module--thumNailUme--ncbRr";
export var thumNails = "otherDrawings-module--thumNails--5K8-I";
export var thumbImg = "otherDrawings-module--thumbImg--nNP4u";